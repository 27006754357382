import React from 'react'
import { graphql } from 'gatsby'
import { renderRichText } from 'gatsby-source-contentful/rich-text'
import * as styles from './contact.module.scss'
import { tr } from 'js/translations.js'
import { useLocalization } from 'gatsby-theme-i18n'

import Banner from 'components/Banner'
import Breadcrumb from 'components/Breadcrumb'
import ContactItem from 'components/ContactItem'
import ImageWrap from 'components/ImageWrap'
import IntroText from 'components/IntroText'
import Inview from 'components/Inview'
import Layout from 'components/Layout'
import Seo from 'components/Seo'

const Page = ({ data }) => {
  const { locale } = useLocalization()
  const page = data[`page_${locale}`].nodes[0]
  const breadcrumb = [
    {
      text: tr('HOME', locale),
      link: '/',
    },
    {
      text: page.title,
    },
  ]

  // let seoDescription = {
  //   en:
  //     'Led by Partners Mr. Chris Wang and Dr. Alex Tianli Zhang, the seasoned team of Hosen Capital has cumulated industry expertise and global vision.',
  //   zh:
  //     '厚生核心团队投资经验丰富，秉持深厚的实业情怀、行业经验和宽广的全球视野。',
  // }
  // seoDescription = seoDescription[locale]
  const contactText = renderRichText(page.contactText)
  return (
    <Layout>
      <div>
        <Seo
          pageTitle={page.title}
          // description={seoDescription}
        />
        <Banner image={page.banner} />
        <Breadcrumb items={breadcrumb} />
        <div className='wrapper pad-sides'>
          <div className='inner'>
            <IntroText content={contactText} />
            <div className={styles.main}>
              <Inview className={`${styles.images} fade-in`}>
                {page.images.map((image, i) => (
                  <ImageWrap image={image} key={i} />
                ))}
              </Inview>
              <Inview>
                <div className={styles.text}>
                  <div className={styles.inner}>
                    <div className={styles.items}>
                      <ContactItem
                        icon='map'
                        label='address'
                        value={page.address}
                      />
                      <ContactItem
                        icon='phone'
                        label='phone'
                        value={page.phone}
                      />
                      <ContactItem
                        icon='email'
                        label='email'
                        value={page.email}
                      />
                    </div>
                  </div>
                </div>
              </Inview>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Page

export const pageQuery = graphql`
  query {
    page_en: allContentfulContactPage(
      filter: { node_locale: { eq: "en-US" } }
    ) {
      nodes {
        title
        banner {
          ...GatsbyImage
        }
        contactText {
          raw
        }
        address
        phone
        email
        images {
          ...GatsbyImage
        }
      }
    }
    page_zh: allContentfulContactPage(
      filter: { node_locale: { eq: "zh-CN" } }
    ) {
      nodes {
        title
        banner {
          ...GatsbyImage
        }
        contactText {
          raw
        }
        address
        phone
        email
        images {
          ...GatsbyImage
        }
      }
    }
  }
`
