import React from 'react'
import * as styles from './styles.module.scss'

import ImagePng from 'components/ImagePng'
import Inview from 'components/Inview'

const Component = ({ icon, label, value }) => {
  return (
    <div className={styles.componentContactItem}>
      <div className={styles.inner}>
        <Inview>
          <div className={`${styles.icon} fade-in-up`}>
            <ImagePng filename={`${icon}.png`} alt='logo' />
          </div>
          {/* <h4>{label}</h4> */}
          {value && (
            <p className='small fade-in-up'>
              {label === 'email' && (
                <a target='_blank' rel='noreferrer' href={`mailto:${value}`}>
                  {value}
                </a>
              )}
              {label !== 'email' && (
                <span dangerouslySetInnerHTML={{ __html: value }} />
              )}
            </p>
          )}
        </Inview>
      </div>
    </div>
  )
}

export default Component
